import React from "react";
import styled from "@emotion/styled";

import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Image from "@narative/gatsby-theme-novela/src/components/Image";
import Section from "@narative/gatsby-theme-novela/src/components/Section";
import Paragraph from "@narative/gatsby-theme-novela/src/components/Paragraph";
import Paginator from "@narative/gatsby-theme-novela/src/components/Navigation/Navigation.Paginator";
import Anchor from "@narative/gatsby-theme-novela/src/components/Anchor";

import AuthorHero from "@narative/gatsby-theme-novela/src/sections/author/Author.Hero";
import AuthorArticles from "@narative/gatsby-theme-novela/src/sections/author/Author.Articles";


import { Template } from "@narative/gatsby-theme-novela/src/types";
import mediaqueries from "@narative/gatsby-theme-novela/src/styles/media";

const ArticlesPage: Template = ({ location, pageContext }) => {
  const author = pageContext.additionalContext.author;
  const articles = pageContext.group;

  return (
    <Layout>
      <SEO
        pathname={location.pathname}
        title={author.name}
        description={author.bio}
      />
      <Section narrow>
        <AuthorHero author={author} />
        <AuthorBody>
          <Paragraph>
            Sincere thanks for your visit!
          </Paragraph>
          <Paragraph>
            Bhayahari das was born in a traditional Hindu family and grew up in New Delhi, India. He came to the United States in 1987 for profession and further studies. 
          </Paragraph>
          <Paragraph>
            Early on he felt a strong need for spiritual shelter and was fortunate to be accepted as a disciple by His Holiness Romapada Swami, a direct disciple of His Divine Grace A.C. Bhaktivedanta Swami Prabhupada, the founder acarya of ISKCON (International Society of Krishna Consciousness) popularly known as the "Hare Krishnas".
          </Paragraph>
          <Paragraph>
            Inspired by his guru, he has attempted to address the need for the principle of Krishna Consciousness to be spread and has sought to bring these ideas to a wider audience.
          </Paragraph>
          <Paragraph>
            He has studied, researched, written and spoken about these principles for over a decade now to reach out to other like minded aspiring spiritualists.
          </Paragraph>
          <Paragraph>
            He lives in Rockville, Maryland with his wife Indulekha devi dasi
          </Paragraph>
        </AuthorBody>
        <AuthorArticles articles={articles} />
        <AuthorPaginator>
          <Paginator {...pageContext} />
        </AuthorPaginator>
      </Section>
      <AuthorsGradient />
    </Layout>
  );
}

export default ArticlesPage;

const AuthorsGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  pointer-events: none;
  background: ${p => p.theme.colors.gradient};
  transition: ${p => p.theme.colorModeTransition};
`;

const AuthorPaginator = styled.div`
  text-align: center;
`;

const AuthorBody = styled.article`
  position: relative;
  padding-left: 68px;
  transition: background 0.2s linear;
  margin-top: 20px;

  ${mediaqueries.desktop`
    padding-left: 53px;
  `}
  
  ${mediaqueries.tablet`
    padding: 70px 0 80px;
  `}

  ${mediaqueries.phablet`
    padding: 60px 0;
  `}
`;

const Hero = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
